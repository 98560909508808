<script lang="ts">
  let clamped: boolean = true
  let item
</script>

<button
  aria-expanded={clamped}
  bind:this={item}
  on:click={() => (clamped = !clamped)}
  class="group/description flex flex-col items-center gap-2 transition-all duration-500 cursor-pointer"
  data-description
>
  <span class:line-clamp-2={clamped} class="text-start">
    <slot />
  </span>
  <div class="group-hover/description:animate-bounce">
    <svg class:rotate-180={!clamped} class="size-6" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5 0L10.5 8L2.5 3.47842e-07V3.55L10.5 11.55L18.5 3.55V0Z" fill="#00FF00"></path>
      <path d="M18.5 9.4502L10.5 17.4502L2.5 9.4502V13.0002L10.5 21.0002L18.5 13.0002V9.4502Z" fill="#00FF00"></path>
    </svg>
  </div>
</button>
